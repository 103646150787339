@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url("https://fonts.googleapis.com/css?family=Playfair+Display:700,900&amp;display=swap");

body{
    background:#fff;
  }

  .img-64{
    width: 50px;
    height: 50px;
  }
  
/* -------------------------------------------------------------------------- */
/*                              Button css starts                             */
/* -------------------------------------------------------------------------- */
.btn{
    color: #000;
    background: linear-gradient(135deg, #f5a878 0%, #f5a878 22%, #f79c63 22%, #f79c63 28%, #f77728 28%, #f77728 72%, #f56207 72%, #f56207 100%);
    background-size: 200% 100%;
    background-position: 0 0;
    font-family:'poppins', sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    padding: 13px 20px 12px;
    border-radius: 32px;
    border: none;
    position: relative;
    z-index: 1;
    transition: all 0.6s ease 0s;
}
.btn:hover{ 
    color: #fff; 
    background-position: 100% 0;
}
.btn:before{ 
    content:"";
    background-color: #fff;
    border-radius: 50px;
    position: absolute;
    top: 6px;
    left: 6px;
    right: 6px;
    bottom: 6px;
    transition: all 0.5s;
    z-index: -1;
}
.btn:hover:before{ opacity: 0; }
@media only screen and (max-width: 767px){
    .btn{ margin-bottom: 30px; }
}

/* ----------------------------- Button css end ----------------------------- */

.img-64{
    width: 50px;
    height: 50px;
  }
  .width-100-sm{
    width: 50%;
  }
  

/* --------------------------- css for video cards -------------------------- */
.video-card{
    width:80vw;
    
  }
  .video{
    clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
    width:30vw;
    height:100%;
    padding: 0px;
  }
  
  .video-right{
    clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);
    width:30vw;
    height:100%;
    padding: 0px;
  }
  /* ---------------------------- End of video card --------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                               Typography Css                               */
  /* -------------------------------------------------------------------------- */
  .font-poppins{
    font-family: 'Poppins', sans-serif;
  }
  
  .text-prime-gradient2{
    background: #FA6C00;
  background: radial-gradient(circle farthest-corner at center center, #FA6C00 40%, #CF1512 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size:210%;
  }

/* -------------------------- End of Typography css ------------------------- */


/* -------------------------------------------------------------------------- */
/*                           Gallery Css starts here                          */
/* -------------------------------------------------------------------------- */

.gallery-wrap {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 70vh;
}

.item {
  flex: 1;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: none;
  transition: flex 0.8s ease;
}
.item:hover{
  flex: 7;

}
/* ------------------------- End of Gallery Css here ------------------------ */


/* -------------------------------------------------------------------------- */
/*                         Testimonial css starts here                        */
/* -------------------------------------------------------------------------- */
.testimonial{
  border-left: 3px solid #FA6C00;
  padding: 100px 0 100px 275px;
  position: relative
}
.testimonial:before,
.testimonial:after{
  content: "";
  width: 320px;
  height: 55px;
  border-right: 3px solid #FA6C00;
  position: absolute;
  left: 0;
}
.testimonial:before{
  border-top: 3px solid #FA6C00;
  top: 0;
}
.testimonial:after{
  border-bottom: 3px solid #FA6C00;
  bottom: 0;
}
.testimonial .pic{
  width: 100px;
  height: 100px;
  position: absolute;
  top: 100px;
  left: 100px;
}
.testimonial .pic img{
  width: 100%;
  height: auto;
}
.testimonial .description{
  font-size: 14px;
  color: #7a7e82;
  line-height: 27px;
  position: relative;
}
.testimonial .description:before{
  /* content: "\f10d"; */
  /* font-family: "Font Awesome 5 Free"; */
  /* font-weight: 900; */
  /* position: absolute; */
  /* top: -70px; */
  /* left: 0; */
  /* font-size: 20px; */
  /* color: #7a7e82; */
}
.testimonial .testimonial-title{
  font-size: 22px;
  font-weight:800;
  color: #22272c;
  text-transform: capitalize;
}
.testimonial .post{
  display: block;
  font-size: 15px;
  font-weight: 700;
  color :#FA6C00;
  margin-top: 10px;
}
.owl-theme .owl-controls{
  text-align: right;
  margin-top: 30px;
}
.owl-theme .owl-controls .owl-buttons div{
  background: #FA6C00;
  border-radius: 0;
  opacity: 1;
  padding: 5px 10px;
}
.owl-prev:before,
.owl-next:before{
 display: none;
  content: "<";
  /* font-family: "Font Awesome 5 Free";  */
  font-weight: 900;
  color: #000;
}
.owl-nav{
  display:none;
}
.owl-next:before{
  content: ">";
}

.owl-dots{
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 30px; */
}
.owl-dots::before{
  content: "11111";
  background-color : #fff;
  color : #fff;
  width: 7.5rem;
  height: 100%;
  transform: translate(100%, 0%);
  border: 3px solid #FA6C00;
  border-radius: 50px;
} 

.owl-dots button.owl-dot.active span, 
.owl-dots button.owl-dot:hover span {
   background-color: yellow;
   border-radius: 50%;
  background: #FA6C00 !important;
   /* height: 16px; */
   /* width: 16px; */
}
.owl-dots button.owl-dot{
  /* border: 5px solid #FA6C00; */
  background: white;
  background-color: #FA6C00;
   border-radius: 50%;
   /* height: 19px; */
   /* width: 19px; */
   position: relative;
}

@media only screen and (max-width: 990px){
  .testimonial{
      padding: 80px 0 80px 265px;
  }
}
@media only screen and (max-width: 767px){
  .testimonial{
      padding: 0;
      border: none;
  }
  .testimonial:before,
  .testimonial:after{
      border: none;
  }
  .testimonial .pic{
      position: relative;
      top: 0;
      left: 0;
  }
  .testimonial .description{
      margin-top: 15px;
  }
  .testimonial .description:before{
      content: "";
  }
}
/* ------------------------- End of Testimonial css ------------------------- */


/* -------------------------------------------------------------------------- */
/*                               css for footer                               */
/* -------------------------------------------------------------------------- */
ul {
  margin: 0px;
  padding: 0px;
}
.footer-section {
background: #151414;
position: relative;
}
.footer-cta {
border-bottom: 1px solid #373636;
}
.single-cta i {
color: #ff5e14;
font-size: 30px;
float: left;
margin-top: 8px;
}
.cta-text {
padding-left: 15px;
display: inline-block;
}
.cta-text h4 {
color: #fff;
font-size: 20px;
font-weight: 600;
margin-bottom: 2px;
}
.cta-text span {
color: #757575;
font-size: 15px;
}
.footer-content {
position: relative;
z-index: 2;
}
.footer-pattern img {
position: absolute;
top: 0;
left: 0;
height: 330px;
background-size: cover;
background-position: 100% 100%;
}
.footer-logo {
margin-bottom: 30px;
}
.footer-logo img {
  max-width: 200px;
}
.footer-text p {
margin-bottom: 14px;
font-size: 14px;
    color: #7e7e7e;
line-height: 28px;
}
.footer-social-icon span {
color: #fff;
display: block;
font-size: 20px;
font-weight: 700;
font-family: 'Poppins', sans-serif;
margin-bottom: 20px;
}
.footer-social-icon a {
color: #fff;
font-size: 16px;
margin-right: 15px;
}
.footer-social-icon i {
height: 40px;
width: 40px;
text-align: center;
line-height: 38px;
border-radius: 50%;
}
.facebook-bg{
background: #3B5998;
}
.twitter-bg{
background: #55ACEE;
}
.google-bg{
background: #DD4B39;
}
.footer-widget-heading h3 {
color: #fff;
font-size: 20px;
font-weight: 600;
margin-bottom: 40px;
position: relative;
}
.footer-widget-heading h3::before {
content: "";
position: absolute;
left: 0;
bottom: -15px;
height: 2px;
width: 50px;
background: #ff5e14;
}
.footer-widget ul li {
display: inline-block;
float: left;
width: 50%;
margin-bottom: 12px;
}
.footer-widget ul li a:hover{
color: #ff5e14;
}
.footer-widget ul li a {
color: #878787;
text-transform: capitalize;
}
.subscribe-form {
position: relative;
overflow: hidden;
}
.subscribe-form input {
width: 100%;
padding: 14px 28px;
background: #2E2E2E;
border: 1px solid #2E2E2E;
color: #fff;
}
.subscribe-form button {
  position: absolute;
  right: 0;
  background: #ff5e14;
  padding: 13px 20px;
  border: 1px solid #ff5e14;
  top: 0;
}
.subscribe-form button i {
color: #fff;
font-size: 22px;
transform: rotate(-6deg);
}
.copyright-area{
background: #202020;
padding: 25px 0;
}
.copyright-text p {
margin: 0;
font-size: 14px;
color: #878787;
}
.copyright-text p a{
color: #ff5e14;
}
.footer-menu li {
display: inline-block;
margin-left: 20px;
}
.footer-menu li:hover a{
color: #ff5e14;
}
.footer-menu li a {
font-size: 14px;
color: #878787;
}

/* ------------------------------- footer end ------------------------------- */



/* -------------------------------------------------------------------------- */
/*                            Blogs Css starts here                           */
/* -------------------------------------------------------------------------- */
.blog-header {
  border-bottom: 1px solid #e5e5e5;
}

.blog-header-logo {
  font-family: "Playfair Display", Georgia, "Times New Roman", serif/*rtl:Amiri, Georgia, "Times New Roman", serif*/;
  font-size: 2.25rem;
}

.blog-header-logo:hover {
  text-decoration: none;
}

h1.blogs-page-heading, h2.blogs-page-heading, h3.blogs-page-heading, h4.blogs-page-heading, h5.blogs-page-heading, h6.blogs-page-heading {
  font-family: "Playfair Display", Georgia, "Times New Roman", serif/*rtl:Amiri, Georgia, "Times New Roman", serif*/;
}

.display-4 {
  font-size: 2.5rem;
}
@media (min-width: 768px) {
  .display-4 {
    font-size: 3rem;
  }
}

.flex-auto {
  flex: 0 0 auto;
}

.h-250 { height: 250px; }
@media (min-width: 768px) {
  .h-md-250 { height: 250px; }
}

/* Pagination */
.blog-pagination {
  margin-bottom: 4rem;
}

/*
 * Blog posts
 */
.blog-post {
  margin-bottom: 4rem;
}
.blog-post-title {
  font-size: 2.5rem;
}
.blog-post-meta {
  margin-bottom: 1.25rem;
  color: #727272;
}

/*
 * Footer
 */
.blog-footer {
  padding: 2.5rem 0;
  color: #727272;
  text-align: center;
  background-color: #f9f9f9;
  border-top: .05rem solid #e5e5e5;
}
.blog-footer p:last-child {
  margin-bottom: 0;
}
.owl-blogs>.owl-dots {
  bottom: 20%;
  position: absolute;
  right: 10%;
}
.blog-top-lg{
background-color: #ff5b00;
background-image: linear-gradient(62deg, #ff5b00ff 0%, #F7CE68ff 75%, #ffffffff 100%);
border: 2px solid #ff5b00;
}

/* for padding-left of image */
.carousel-image{
  padding-left: 150px;
}

/* ---------------------------- End of Blogs Css ---------------------------- */


/* -------------------------------------------------------------------------- */
/*                              Contact form css                              */
/* -------------------------------------------------------------------------- */
.contact-fp-div {
background: rgba( 255, 255, 255, 0.45 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 8px );
-webkit-backdrop-filter: blur( 8px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
}
/* --------------------------- End of Contact form -------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                             Desktop media query                            */
  /* -------------------------------------------------------------------------- */
  @media only screen and (max-width: 768px){
    .button-carousel{
      display:none;
    }
    .para-1{
      /* line-height: 11px; */
      font-size: 80%;
    }
    hr{
      display:none;
    }
    .text-outline{
    transform:translate(0%,-20%);
    -webkit-text-stroke-width: 0px;
    -webkit-text-stroke-color: black;
    }
    .offerbanner-toggler{
      display: none;
      
    }
    .img-change{
      height:30vh;
    }
    .card-glass{
      backdrop-filter: blur(2px) saturate(180%);
      -webkit-backdrop-filter: blur(2px) saturate(180%);
      background-color: rgba(255, 255, 255, 0.7);
      border-radius: 12px;
      border: 1px solid rgba(209, 213, 219, 0.3);
    font-size:20px;
    }
    .fpb-image{
      height:20vh;
      background-size:cover;
      background-repeat: no-repeat;
      transform:translate(3%,0%);
      /* border:5px; */
    }
    .width-100-sm{
    width: 100%;
  }
  }
  /* ----------------------- End of desktop media query ----------------------- */

  @media only screen and (max-width: 480px){
    .carousel-image{
      display: none !important;
    }
    /* --------------------- blog images sizing for mobiles --------------------- */
    img.h-50.w-50.img-fluid{
      height: 75% !important;
      width: 75% !important;
    }
    img.h-50.w-50.img-fluid + div{
      width: 75% !important;
    }
  }


  /* ------------------ css for contact form submitted alert ------------------ */
  .alert-container {
    position: fixed;
    top: 70px; /* Adjust this value to position the alert below the navbar */
    width: 80%;
    z-index: 100;
    margin-top: 0.5rem;
  }
  
  .alert {
    position: relative;
    z-index: 1;
  }
  
  .content {
    margin-top: 120px; /* Adjust this value to create space below the fixed alert */
  }  
/* -------------------- End of css for contact form alert ------------------- */
div.blank-fix-2 {
  height: 8rem;
}