@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');

.newsize {
  max-width: 100%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

nav {}

/* .div-body{
    background: rgb(131,58,180);
    background: radial-gradient(circle, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);
} */

.font-poppins {
  font-family: 'Poppins', sans-serif;
}

.text-prime-gradient {
  background: #FA6C00;
  background: radial-gradient(circle farthest-corner at center center, #FA6C00 40%, #CF1512 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 105%;
}

.text-prime-gradient2 {
  background: #FA6C00;
  background: radial-gradient(circle farthest-corner at center center, #FA6C00 40%, #CF1512 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 210%;
}

.text-prime .active:focus {
  color: #000 !important;
}

.text-prime .active {
  color: #FA6C00 !important;
}

.text-prime .active:hover {
  color: #000 !important;
}

.disabled .text-prime {
  color: #FA6C0099 !important;
}

/* -------------------------------------------------------------------------- */
/*                          CSS for menu product grid                         */
/* -------------------------------------------------------------------------- */

.product-grid {
  font-family: 'Lato', sans-serif;
  text-align: center;
  overflow: hidden;
}

.product-grid .product-image {
  overflow: hidden;
  position: relative;
}

.product-grid .product-image a.image {
  display: block;
}

.product-grid .product-image img {
  width: 100%;
  height: auto;
}

.product-image .pic-1 {
  backface-visibility: hidden;
  transition: all 0.5s ease-in-out;
}

.product-grid:hover .product-image .pic-1 {
  opacity: 0;
}

.product-image .pic-2 {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.5s ease;
}

.product-grid:hover .product-image .pic-2 {
  opacity: 1;
}

.product-grid .product-sale-label {
  color: #fff;
  background: #f55f1e;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 1px 6px;
  position: absolute;
  top: 15px;
  left: 15px;
}

.product-grid .product-links {
  padding: 0;
  margin: 0;
  list-style: none;
  position: absolute;
  bottom: 10px;
  right: -50px;
  transition: all .5s ease 0s;
}

.product-grid:hover .product-links {
  right: 15px;
}

.product-grid .product-links li {
  margin: 4px 0;
  transition: all 0.3s ease 0s;
}

.product-grid .product-links li a {
  color: #191919;
  background: #fff;
  font-size: 16px;
  text-align: center;
  line-height: 36px;
  width: 38px;
  height: 38px;
  border: 1px solid #e7e7e7;
  display: block;
  transition: all 0.3s;
}

.product-grid .product-links li a:hover {
  color: #fff;
  background: #f55f1e;
  border-color: #f55f1e;
}

.product-grid .product-content {
  padding: 12px 0 0;
  text-align: left;
}

.product-grid .price {
  color: #f55f1e;
  font-size: 16px;
  font-weight: 400;
  margin: 0 0 5px;
}

.product-grid .price span {
  color: #707070;
  font-size: 15px;
  text-decoration: line-through;
}

.product-grid .title {
  font-size: 17px;
  font-weight: 500;
  text-transform: capitalize;
  margin: 0 0 5px;
}

.product-grid .title a {
  color: #191919;
  transition: all 0.3s ease 0s;
}

.product-grid .product-category {
  color: #939393;
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
  width: calc(100% - 85px);
  display: inline-block;
}

.product-grid .product-category a {
  color: #939393;
  transition: all 0.3s ease 0s;
}

.product-grid .title a:hover,
.product-grid .product-category a:hover {
  color: #f55f1e;
}

.product-grid .rating {
  color: #eac407;
  font-size: 12px;
  width: 80px;
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-block;
  vertical-align: top;
}

span.product-category.list-group-item>a {
  font-size: 8px;
}

button[id^="v-pills"] {
  color: #8b8a8a;
}

button[id^="v-pills"]:hover {
  color: #FA6C00;
}

button[id^="v-pills"].active {
  border-right: 0.3rem solid #FA6C00;
  border-bottom: 0.05rem solid #FA6C00;
  border-top: 0.05rem solid #FA6C00;
  text-decoration: none;
  color: #FA6C00;
}

div.nav.flex-column {
  position: sticky;
  top: 13%;
}

@media screen and (max-width: 990px) {
  .product-grid {
    margin-bottom: 30px;
  }
}

/* --------------------- CSS for product grid ends here --------------------- */

/* -------------------------------------------------------------------------- */
/*                            About CSS starts here                           */
/* -------------------------------------------------------------------------- */

.text-about {
  text-shadow: 5px 5px #FA6C00;
  font-size: 13rem;
}

button[id^="ex3-tab-"] {
  color: #8b8a8a;
  border-bottom: 0.05rem solid #8b8a8a;
}

button[id^="ex3-tab-"]:hover {
  color: #FA6C00;
}

button[id^="ex3-tab-"].active {
  border-bottom: 0.3rem solid #FA6C00;
  text-decoration: none;
  color: #FA6C00;
}

.justify-center-text {
  margin: 0;
  text-align: justify;
}

/* --------------------------- About CSS ends here -------------------------- */

/* # Mobile */
@media only screen and (max-width: 480px) {
  .navbar-toggler {
    display: none;
  }
  .text-about{
    font-size: 5rem;
  }
  ul#ex1{
    width: 100vw;
  }
  div.nav.flex-column {
    top: 10%;
  }
  div.blank-fix {
    height: 55rem;
  }
  div.blank-fix-2 {
    height: 60rem;
  }

  main.contact {
    display: none;
  }

  .text-prime-gradient2 {
    font-size: 105%;
  }
}

/* # Tablet */
@media only screen and (min-width: 768px) {}

/* # Desktop */
@media only screen and (min-width: 992px) {
  .navbar-toggler {
    display: none;
  }
}

/* # Huge */
@media only screen and (min-width: 1280px) {
  .navbar-toggler {
    display: none;
  }
}