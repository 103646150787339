*, *::before, *::after {
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  body {
    background-color: var(--background-color);
    color: white;
  }
  
  :root {
    --icon-size: 2rem;
    --indicator-spacing: calc(var(--icon-size) / 8);
    --border-radius: calc(var(--icon-size) / 4);
    --nav-item-padding: calc(var(--icon-size) / 2);
    --background-color: #333;
  }
  
  .sticky-bottom {
    background-color: var(--background-color) !important;
    /* border: 20px; */
    border-radius: var(--border-radius);
    width: --webkit-fill-avilable;
    margin: 0 auto;
    margin-top: 3rem;
    align-items: center;
    /* margin-top: 10rem; */
    padding: 0 calc(var(--nav-item-padding) * 1.5);
  }
  
  .list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .list a,.list a:focus {
    color: white;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--nav-item-padding);
  }
  
  .list .text {
    font-size: .8em;
    opacity: 0;
    pointer-events: none;
    transition: 250ms ease-in-out;
    position: absolute;
    bottom: calc(.5 * var(--nav-item-padding));
    transform: translateY(50%);
  }
  
  .list .icon {
    position: relative;
    transition: 250ms ease-in-out;
  }
  
  .list .icon svg {
    fill: currentColor;
    width: var(--icon-size);
    height: var(--icon-size);
    display: block;
  }
  
  .list .active .text {
    pointer-events: all;
    opacity: 1;
    transform: translateY(0);
  }
  
  .list .active .icon {
    transform: translateY(calc(-50% - var(--nav-item-padding)));
  }
  
  /* .list .active::before {
    content: "";
    box-sizing: content-box;
    position: absolute;
    width: var(--border-radius);
    height: var(--border-radius);
    background-color: white;
    z-index: 1;
    top: calc(-1 * var(--indicator-spacing));
    left: calc(.2 * var(--indicator-spacing));
    transform: translateX(-100%);
    border-top-right-radius: 100%;
    border-width: calc(var(--indicator-spacing));
    border-color: var(--background-color);
    border-style: solid;
    border-bottom: none;
    border-left: none;
  }
  
  .list .active::after {
    content: "";
    box-sizing: content-box;
    position: absolute;
    width: var(--border-radius);
    height: var(--border-radius);
    background-color: white;
    z-index: 1;
    top: calc(-1 * var(--indicator-spacing));
    right: calc(.2 * var(--indicator-spacing));
    transform: translateX(100%);
    border-top-left-radius: 100%;
    border-width: calc(var(--indicator-spacing));
    border-color: var(--background-color);
    border-style: solid;
    border-bottom: none;
    border-right: none;
  } */
  
  .list {
    position: relative;
  }
  
  .indicator {
    position: absolute;
    left: calc(var(--position) * (var(--icon-size) + var(--nav-item-padding) * 2));
    transition: 250ms ease-in-out;
  }
  
  .indicator::after,
  .indicator::before {
    content: "";
    position: absolute;
    border-radius: 100%;
  }
  
  .indicator::after {
    background-color: #FA6C00;
    width: calc(var(--icon-size) * 2);
    height: calc(var(--icon-size) * 2);
    top: calc(-1 * var(--icon-size));
  }
  .indicator::before {
    background-color: white;
    width: calc((var(--icon-size) + var(--indicator-spacing)) * 2);
    height: calc((var(--icon-size) + var(--indicator-spacing)) * 2);
    top: calc(-1 * var(--icon-size) - var(--indicator-spacing));
    left: calc(-1 * var(--indicator-spacing));
  }
  
  .corners::before {
    content: "";
    box-sizing: content-box;
    position: absolute;
    width: var(--border-radius);
    height: var(--border-radius);
    background-color: var(--background-color);
    z-index: 1;
    top: calc(-1 * var(--indicator-spacing));
    left: calc(.2 * var(--indicator-spacing));
    transform: translateX(-100%);
    border-top-right-radius: 100%;
    border-width: calc(var(--indicator-spacing));
    border-color: white;
    border-style: solid;
    border-bottom: none;
    border-left: none;
  }
  
  .corners::after {
    content: "";
    box-sizing: content-box;
    position: absolute;
    width: var(--border-radius);
    height: var(--border-radius);
    background-color: var(--background-color);
    z-index: 1;
    top: calc(-1 * var(--indicator-spacing));
    left: calc(var(--icon-size) * 2 + -.2 * var(--indicator-spacing));
    border-top-left-radius: 100%;
    border-width: calc(var(--indicator-spacing));
    border-color: var(white);
    border-style: solid;
    border-bottom: none;
    border-right: none;
  }

  @media only screen and (min-width: 480px){
    div.shadow.sticky-bottom{
        display: none !important;
      }
    }
  